import axios from "axios";

const baseUrl = process.env.VUE_APP_BASE_URL;

const ExchangeRatesApi = {
  getAllRates: () => {
    const res = axios({
      url: `${baseUrl}/admin/exchange-rates`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getAllShippingRates: () => {
    const res = axios({
      url: `${baseUrl}/admin/get-shipping-rates`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getShippingRate: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/get-shipping-rates/${payload.id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  createRate: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/exchange-rate/create`,
      method: "POST",
      data: JSON.stringify({
        currency: payload.currency,
        currencyAbbreviation: payload.currencyAbbreviation,
        amount: payload.amount,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  createShippingRates: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/create-shipping-rate`,
      method: "POST",
      data: JSON.stringify({
        freightType: payload.freightType,
        freightName: payload.freightName,
        weightUnit: payload.weightUnit,
        rate: payload.rate,
        currency: payload.currency,
        location: payload.location,
        destination: payload.destination,
        isSpecialGoods: payload.isSpecialGoods,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  updateShippingRates: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/update-rate/${payload.shippingRateId}`,
      method: "PUT",
      data: JSON.stringify({
        rate: payload.rate,
        freightName: payload.freightName,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  updateCurrencyRate: (payload) => {
    const res = axios({
      url: `${baseUrl}/admin/exchange-rate/update/${payload.rate_id}`,
      method: "PUT",
      data: JSON.stringify({
        currency: payload.currency,
        currencyAbbreviation: payload.currencyAbbreviation,
        amount: payload.amount,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default ExchangeRatesApi;
